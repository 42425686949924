<template>
    <v-dialog v-model="showDialog" max-width="500px">
         <v-card>
         <v-card-title>
                <span>Ref: {{shipment.order_reference}} </span>
                   <v-spacer></v-spacer>
                 <v-btn icon @click="showDialog = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
        </v-card-title>
        <pdf @num-pages="actualizarTotalPaginacion" :page="pagina" style="width: 100%;" :src="url"></pdf>
        <v-row>
            <v-col>
                <v-card-actions>
            <v-pagination v-model="pagina" :length="totalPaginacion"></v-pagination>
            </v-card-actions>
            </v-col>
        </v-row>
            
           
        
    </v-card>
    </v-dialog>
</template>

<style lang="scss">
.pagination.pagination-sm{
    margin-bottom: 0.1rem;
}
</style>

<script>
import EventBus from '@/event-bus';
import pdf from 'vue-pdf';

export default {
    components: {
        pdf
    },
    data(){
        return {
            showDialog: false,
            shipment: {
                order_reference: undefined,
            },
            url: 'https://api.pos.garnetek.xyz/blanco.pdf',
            pagina: 1,
            totalPaginacion: 1,
            newBlob: null,
            data: null,

        }
    },
    created(){
        var vm = this;
        EventBus.$on('openDialogPreviewLabel', (shipment) => {
            vm.shipment = shipment;
            vm.showDialog = true;
            vm.newBlob = new Blob([vm.shipment.pdf], {type: "application/pdf"});
            vm.data = window.URL.createObjectURL(vm.newBlob);
            vm.url = vm.shipment.pdf;
            vm.order_reference = vm.shipment.order_reference;
        });
    },
    methods: {
        actualizarTotalPaginacion(value){
            this.totalPaginacion = value;
        }
    },
}
</script>